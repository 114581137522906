import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../Config/AxiosInstance";
import { ApiList } from "../../Config/ApiList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PathList from "../../Common/PathList";
import Loader from "../../Components/Loader";
import DUMMY from "../../Assets/profile1.jpg";
const BusinessDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [getSingleUser, setGetSingleUser] = useState([]);
  const [loading, setLoading] = useState(true);

  const GET_BY_ID = async () => {
    try {
      setLoading(true);
      const res = await AxiosInstance.get(ApiList.GET_BY_ID, {
        params: {
          perm: "id",
          value: location?.state?.data,
        },
      });
      if (res && res?.data?.s === 1) {
        setGetSingleUser(res?.data?.r);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    GET_BY_ID();
  }, []);
  console.log(getSingleUser, "user");

  return (
    <>
      <Box sx={{ width: "100%", height: "100vh" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          <Box sx={{ width: "100px" }}>
            <IconButton
              sx={{ p: 2 }}
              onClick={() => navigate(PathList.BusinessManagement)}
            >
              <ArrowBackIcon sx={{ color: "#668B4B", fontSize: "38px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "calc(100% - 100px)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "30px", fontWeight: "600", color: "#668B4B" }}
            >
              Business Details
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "calc(100% - 70px)",
            flexDirection: { xs: "column", xl: "row" },
          }}
        >
          <Box
            sx={{
              p: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                width: { xs: "100%", xl: "350px" },
                bgcolor: "#f1f0f0",
                borderRadius: "12px",
                p: 1,
                display: "flex",
                flexDirection: { xs: "row", xl: "column" },
              }}
            >
              {loading ? (
                <Box
                  sx={{ width: "350px", height: "500px", bgcolor: "#f1f0f0" }}
                >
                  <Loader />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "180px",
                        height: "180px",
                        margin: "20px",
                        borderRadius: "12px",
                      }}
                    >
                      <img
                        src={
                          getSingleUser?.user_profile
                            ? `https://api-yokey.equitysoft.net/upload/business/logo/${getSingleUser?.business_logo}`
                            : DUMMY
                        }
                        alt="img"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: "14px", color: "#B3B3B3" }}>
                        Business Name
                      </Typography>
                      <Typography sx={{ fontSize: "18px", color: "black" }}>
                        {getSingleUser?.business_name
                          ? getSingleUser?.business_name
                          : "--"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "14px", color: "#B3B3B3" }}>
                        Email Address
                      </Typography>
                      <Typography sx={{ fontSize: "18px", color: "black" }}>
                        {getSingleUser?.email ? getSingleUser?.email : "--"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "14px", color: "#B3B3B3" }}>
                        Phone Number
                      </Typography>
                      <Typography sx={{ fontSize: "18px", color: "black" }}>
                        {getSingleUser?.country_code && getSingleUser?.mo_number
                          ? `${getSingleUser?.country_code} ${getSingleUser?.mo_number}`
                          : "--"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "14px", color: "#B3B3B3" }}>
                        Date Of Birth
                      </Typography>
                      <Typography sx={{ fontSize: "18px", color: "black" }}>
                        {getSingleUser?.dob ? getSingleUser?.dob : "--"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                        borderRadius: "8px",
                        bgcolor:
                          getSingleUser?.is_approved === 1
                            ? "#80AD3560"
                            : getSingleUser?.is_approved === -1
                            ? "#FFDADA"
                            : getSingleUser?.is_approved === 0 && "#e6d81e60",
                        margin: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: "black",
                          fontWeight: "500",
                          px: 2,
                          py: 0.5,
                        }}
                      >
                        {getSingleUser?.is_approved === 1
                          ? "Approved"
                          : getSingleUser?.is_approved === -1
                          ? "Rejected"
                          : getSingleUser?.is_approved === 0 && "Pending"}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", xl: "calc(100% - 350px)" } }}></Box>
        </Box>
      </Box>
    </>
  );
};

export default BusinessDetails;
