import PathList from "../../Common/PathList";
import BusinessA from "../../Assets/sidebarActiveicons/BusinessA.png";
import DashboardA from "../../Assets/sidebarActiveicons/DashboardA.png";
import Business from "../../Assets/SidebarInactiveicons/Business.png";
import Dashboard from "../../Assets/SidebarInactiveicons/Dashboard.png";
import Notifications from "../../Assets/SidebarInactiveicons/Notifications.png";
import NotificationsA from "../../Assets/sidebarActiveicons/NotificationsA.png";
import Category from "../../Assets/SidebarInactiveicons/Category.png"
import CategoryA from "../../Assets/sidebarActiveicons/CategoryA.png"

export const NavMenuList = [
  {
    title: "Dashboard",
    path: PathList.Dashboard,
    active: DashboardA,
    icon: Dashboard,
  },
  {
    title: "Content Creator",
    path: PathList.ContentCreator,
    active: BusinessA,
    icon: Business,
  },
  {
    title: "Business",
    path: PathList.BusinessManagement,
    active: BusinessA,
    icon: Business,
  },
  {
    title: "Category",
    path: PathList.Category,
    active: CategoryA,
    icon: Category,
  },
  {
    title: "Notifications",
    path: PathList.Notifications,
    active: NotificationsA,
    icon: Notifications,
  },
  {
    title: "Social Media",
    path: PathList.SocialMedia,
    active: DashboardA,
    icon: Dashboard,
  },
];
