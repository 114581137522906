import axios from "axios";
const AxiosInstance = axios.create({
  baseURL: "http://api-yokey.equitysoft.net/api",
});
AxiosInstance.interceptors.request.use(
  function (config) {
    const yokey = JSON.parse(localStorage.getItem("YOKEY"));
    config.headers.token = yokey?.token;
    config.headers.apikey = yokey?.apikey;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("userInfo");
        window.location.pathname = "/";
      } else {
        console.error("Response Error:", error.response);
      }
    } else if (error.request) {
      console.error("Request Error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance