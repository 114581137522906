const PathList = {
    Login: "/",
    Dashboard:"/dashboard",
    ContentCreator:"/content-creator",
    ContentCreatorDetails:"/content-creator/content-creator-details",
    BusinessManagement:"/business-management",
    BusinessDetails:"/business-management/business-details",
    Notifications:"/notifications",
    Category:"/category-management",
    SocialMedia:"/social-media"
}

export default PathList
