import React, { useEffect, useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import TableContainer from "../../Components/TableContainer";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../Components/CustomButton";
import AxiosInstance from "../../Config/AxiosInstance";
import { ApiList } from "../../Config/ApiList";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
const CategoryManagement = () => {
  const pageSize = 10;
  const [isFeedLoading, setFeedIsLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [editFeedID, setEditFeedId] = useState(null);
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Category Name",
      flex: 1,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            width: "150px",
            display: "flex",
            justifyContent: "space-evenly",
            color: "white",
            borderRadius: "8px",
          }}
        >
          <IconButton onClick={() => handleEditCategory(params?.row)}>
            <ModeEditOutlineIcon sx={{ color: "#668B4B", fontSize: "28px" }} />
          </IconButton>
          <IconButton onClick={() => handleDeleteCategory(params?.row?.id)}>
            <DeleteIcon sx={{ color: "#903C39", fontSize: "28px" }} />
          </IconButton>
        </Box>
      ),
    },
  ];
  const handleDeleteCategory = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Category",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          status: 0,
        };
        try {
          const response = await AxiosInstance.post(
            ApiList.UPDATE_CATEGORY,
            params
          );
          if (response?.data?.s === 1) {
            toast.success("Category Deleted Sucessfully !.");
            getCategoryList();
          }
        } catch (error) {
          console.log(error);
          toast.error("Failed to delete Category. Please try again.");
        }
      }
    });
  };
  const handleEditCategory = (elm) => {
    if (elm) {
      feedformik.setFieldValue("CategoryName", elm?.name);
      setTypeCheck("Edit");
      setEditFeedId(elm?.id);
    }
  };

  const getCategoryList = async () => {
    try {
      setFeedIsLoading(true);
      const fetchFeed = await AxiosInstance.get(ApiList.GET_CATEGORY);
      if (fetchFeed?.data?.s) {
        const formattedUsers = fetchFeed?.data?.r?.map((user, index) => ({
          rowid: index + 1,
          ...user,
        }));
        setCategoryList(formattedUsers);
      }
      setFeedIsLoading(false);
    } catch (error) {
      console.log(error);
      setFeedIsLoading(false);
    }
  };
  useEffect(() => {
    getCategoryList();
  }, []);
  const feedformik = useFormik({
    initialValues: {
      CategoryName: "",
    },
    validationSchema: Yup.object().shape({
      CategoryName: Yup.string().required("Category name is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setFeedIsLoading(true);
      try {
        const formData = new FormData();
        if (typeCheck === "Edit") {
          formData.append("id", editFeedID);
        }
        formData.append("name", values.CategoryName);
        const api_endpoint =
          typeCheck === "Edit" ? ApiList.UPDATE_CATEGORY : ApiList.ADD_CATEGORY;
        const res = await AxiosInstance.post(api_endpoint, formData);
        if (res?.data?.s) {
          resetForm();
          getCategoryList();
          setFeedIsLoading(false);
          setTypeCheck(null);
          toast.success(
            `category ${
              typeCheck === "Edit" ? "updated" : "added"
            } successfully`
          );
        }
        setFeedIsLoading(false);
      } catch (error) {
        setFeedIsLoading(false);
        console.log(error);
      }
    },
  });
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        overflow: "auto",
        p: 3,
      }}
    >
      <Typography
        sx={{
          fontSize: "30px",
          fontWeight: "600",
          color: "#668B4B",
        }}
      >
        Category
      </Typography>
      <Box
        sx={{
          py: 2.4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "50px",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "30px",
              alignItems: "start",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline ": {
                    outline: "none",
                    border: "none",
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "15px",
                    border: "none",
                    background: "#f1f0f0",
                    height: "55px",
                    fontSize: "16px",
                  },
                }}
                type={"text"}
                placeholder={"Please Enter Category"}
                name={"CategoryName"}
                value={feedformik.values.CategoryName}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  feedformik.handleChange(e);
                }}
                onBlur={feedformik.handleBlur}
                onKeyUp={feedformik.handleBlur}
              />
              {feedformik.touched.CategoryName &&
              feedformik.errors.CategoryName ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                  }}
                >
                  {feedformik.errors.CategoryName}
                </Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gridGap: "25px",
              }}
            >
              <CustomButton
                title={typeCheck === "Edit" ? "Update" : "Add"}
                sx={{
                  width: "130px",
                }}
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  feedformik.handleSubmit();
                  return false;
                }}
                loading={isFeedLoading}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "30px",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              mt: 3,
              width: "100%",
            }}
          >
            <TableContainer
              sx={{ height: { xs: "300px", md: "350px", xl: "600px" } }}
              rows={categoryList}
              columns={columns}
              pageSize={pageSize}
              loading={isFeedLoading}
              hideFooter={true}
              pagination={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CategoryManagement;
