import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import TopTitle from "../../Components/TopTitle";
import PathList from "../../Common/PathList";
import { toast } from "react-toastify";
import { ApiList } from "../../Config/ApiList";
import AxiosInstance from "../../Config/AxiosInstance";
import moment from "moment";
import TableContainer from "../../Components/TableContainer";
import Loader from "../../Components/Loader";
import Swal from "sweetalert2";
// import CloseIcon from "@mui/icons-material/Close";
// import CheckIcon from "@mui/icons-material/Check";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import DeleteIcon from "@mui/icons-material/Delete";
const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [contentCreatorList, setContentCreatorList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1) + "B"; // Billions
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + "M"; // Millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Thousands
    } else {
      return num.toString(); // Less than 1K
    }
  }
  const getDashboardAnalytics = async () => {
    try {
      setDashboardLoading(true);
      const res = await AxiosInstance.get(ApiList.DASHBOARD_DETAILS);
      if (res && res?.data?.s === 1) {
        setDashboardDetails(res?.data?.r);
      }
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };

  const columns1 = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "full_name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return `${params?.row?.f_name} ${params?.row?.l_name}`
          ? `${params?.row?.f_name} ${params?.row?.l_name}`
          : "--";
      },
    },
    {
      field: "user_name",
      headerName: "User Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return params?.row?.user_name ? `${params?.row?.user_name}` : "--";
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      minWidth: 100,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        let gender;
        if (params?.row?.gender) {
          if (params?.row?.gender === 1) {
            return (gender = "Male");
          } else if (params?.row?.gender === 2) {
            return (gender = "Female");
          } else {
            return (gender = "Other");
          }
        }
        return (
          <Typography>{gender}</Typography>
        );
      },
    },
    {
      field: "dob",
      headerName: "DOB",
      flex: 1,
      minWidth: 100,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return params?.row?.dob ? params.row.dob : "--";
      },
    },
    {
      field: "mo_number",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return params?.row?.country_code && params?.row?.mo_number
          ? `${params?.row?.country_code} ${params?.row?.mo_number}`
          : "--";
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 200,
    //   headerAlign: "center",
    //   headerClassName: "center",
    //   cellClassName: "center",
    //   renderCell: (params) => {
    //     if (params?.row?.is_approved === 1) {
    //       return (
    //         <Box
    //           sx={{
    //             width: "150px",
    //             display: "flex",
    //             justifyContent: "center",
    //             bgcolor: "#668B4B",
    //             color: "white",
    //             borderRadius: "8px",
    //           }}
    //         >
    //           <Typography sx={{ p: 1 }}>Approved</Typography>
    //         </Box>
    //       );
    //     } else if (params?.row?.is_approved === -1) {
    //       return (
    //         <Box
    //           sx={{
    //             width: "150px",
    //             display: "flex",
    //             justifyContent: "center",
    //             bgcolor: "#903C39",
    //             color: "white",
    //             borderRadius: "8px",
    //           }}
    //         >
    //           <Typography sx={{ p: 1 }}>Rejected</Typography>
    //         </Box>
    //       );
    //     } else if (params?.row?.is_approved === 0) {
    //       return (
    //         <Box
    //           sx={{
    //             width: "150px",
    //             display: "flex",
    //             justifyContent: "space-evenly",
    //             color: "white",
    //             borderRadius: "8px",
    //           }}
    //         >
    //           <IconButton
    //             onClick={() => handleBlockUnBlock(params?.row?.id, 1)}
    //             sx={{
    //               bgcolor: "#668B4B",
    //               "&:hover": { bgcolor: "#668B4B" },
    //               borderRadius: "8px",
    //             }}
    //           >
    //             <CheckIcon sx={{ color: "white", fontSize: "18px" }} />
    //           </IconButton>
    //           <IconButton
    //             onClick={() => handleBlockUnBlock(params?.row?.id, -1)}
    //             sx={{
    //               bgcolor: "#903C39",
    //               "&:hover": { bgcolor: "#903C39" },
    //               borderRadius: "8px",
    //             }}
    //           >
    //             <CloseIcon sx={{ color: "white", fontSize: "18px" }} />
    //           </IconButton>
    //         </Box>
    //       );
    //     }
    //     return "--";
    //   },
    // },
    // {
    //   field: "Action",
    //   headerName: "Action",
    //   minWidth: 100,
    //   headerClassName: "center",
    //   cellClassName: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           width: "150px",
    //           display: "flex",
    //           justifyContent: "space-evenly",
    //           color: "white",
    //           borderRadius: "8px",
    //         }}
    //       >
    //         <IconButton
    //           onClick={() =>
    //             navigate(PathList.ContentCreatorDetails, {
    //               state: {
    //                 data: params?.row?.id,
    //               },
    //             })
    //           }
    //         >
    //           <RemoveRedEyeIcon sx={{ color: "#668B4B", fontSize: "28px" }} />
    //         </IconButton>
    //         <IconButton
    //           onClick={() => handleDelete(params?.row?.id)}
    //           disabled={params?.row?.status === -1 ? true : false}
    //         >
    //           <DeleteIcon
    //             sx={{
    //               color: params?.row?.status === -1 ? "grey" : "#903C39",
    //               fontSize: "28px",
    //             }}
    //           />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];
  useEffect(() => {
    getContentCreator();
    getDashboardAnalytics();
  }, []);
  // const handleBlockUnBlock = async (userId, isStatus) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text:
  //       isStatus === -1
  //         ? "You want to Rejecte this user"
  //         : "You want to Approve this user",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#46adf2",
  //     cancelButtonColor: "#5b5d5d",
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "Cancel",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const formData = new FormData();
  //         formData.append("id", userId);
  //         formData.append("is_approved", isStatus);
  //         const res = await AxiosInstance.post(
  //           ApiList.APPROVE_REJECT,
  //           formData
  //         );
  //         if (res?.data?.s) {
  //           toast.success(
  //             `User ${isStatus === -1 ? "Rejected" : "Approved"} Sucessfully !.`
  //           );
  //           getBusinessList();
  //         }
  //         console.log(res);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   });
  // };
  const handleBlockUnBlockBusiness = async (userId, isStatus) => {
    Swal.fire({
      title: "Are you sure?",
      text:
        isStatus === -1
          ? "You want to Rejecte this user"
          : "You want to Approve this user",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const formData = new FormData();
          formData.append("id", userId);
          formData.append("is_approved", isStatus);
          const res = await AxiosInstance.post(
            ApiList.APPROVE_REJECT,
            formData
          );
          if (res?.data?.s) {
            toast.success(
              `User ${isStatus === -1 ? "Rejected" : "Approved"} Sucessfully !.`
            );
            getContentCreator();
          }
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  // const handleDelete = async (userId) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to delete this influencer",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#46adf2",
  //     cancelButtonColor: "#5b5d5d",
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "Cancel",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       try {
  //         const formData = new FormData();
  //         formData.append("id", userId);
  //         formData.append("status", -1);
  //         const res = await AxiosInstance.post(ApiList.DELETE_USER, formData);
  //         if (res?.data?.s) {
  //           toast.success(`Influencer Deleted Sucessfully`);
  //           getContentCreator();
  //         }
  //         console.log(res);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   });
  // };
  const getContentCreator = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(ApiList.GET_USER_LIST, {
        params: {
          role: 1,
        },
      });
      if (response && response?.data?.s === 1) {
        const formattedUsers = response?.data?.r?.map((user, index) => ({
          rowid: index + 1,
          ...user,
          dob: moment(user?.dob).format("DD/MM/YYYY"),
        }));
        setContentCreatorList(formattedUsers);
      } else {
        setContentCreatorList([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const columns = [
    {
      field: "rowid",
      headerName: "Sr.No",
      width: 100,
      align: "center",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "business_name",
      headerName: "Business Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return params?.row?.business_name ? params?.row?.business_name : "--";
      },
    },
    // {
    //   field: "kbis",
    //   headerName: "KBIS",
    //   flex: 1,
    //   minWidth: 200,
    //   headerClassName: "leftAlignHeader",
    //   cellClassName: "leftAlign",
    //   renderCell: (params) => {
    //     return params?.row?.kbis ? `${params?.row?.kbis}` : "--";
    //   },
    // },
    {
      field: "website",
      headerName: "Website",
      flex: 1,
      minWidth: 200,
      headerClassName: "leftAlignHeader",
      cellClassName: "leftAlign",
      renderCell: (params) => {
        return params?.row?.website ? `${params?.row?.website}` : "--";
      },
    },
    // {
    //   field: "dob",
    //   headerName: "DOB",
    //   flex: 1,
    //   minWidth: 100,
    //   headerClassName: "leftAlignHeader",
    //   cellClassName: "leftAlign",
    //   renderCell: (params) => {
    //     return params?.row?.dob ? params.row.dob : "--";
    //   },
    // },
    // {
    //   field: "mo_number",
    //   headerName: "Mobile Number",
    //   flex: 1,
    //   minWidth: 200,
    //   headerClassName: "leftAlignHeader",
    //   cellClassName: "leftAlign",
    //   renderCell: (params) => {
    //     return params?.row?.country_code && params?.row?.mo_number
    //       ? `${params?.row?.country_code} ${params?.row?.mo_number}`
    //       : "--";
    //   },
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 200,
    //   headerAlign: "center",
    //   headerClassName: "center",
    //   cellClassName: "center",
    //   renderCell: (params) => {
    //     if (params?.row?.is_approved === 1) {
    //       return (
    //         <Box
    //           sx={{
    //             width: "150px",
    //             display: "flex",
    //             justifyContent: "center",
    //             bgcolor: "#668B4B",
    //             color: "white",
    //             borderRadius: "8px",
    //           }}
    //         >
    //           <Typography sx={{ p: 1 }}>Approved</Typography>
    //         </Box>
    //       );
    //     } else if (params?.row?.is_approved === -1) {
    //       return (
    //         <Box
    //           sx={{
    //             width: "150px",
    //             display: "flex",
    //             justifyContent: "center",
    //             bgcolor: "#903C39",
    //             color: "white",
    //             borderRadius: "8px",
    //           }}
    //         >
    //           <Typography sx={{ p: 1 }}>Rejected</Typography>
    //         </Box>
    //       );
    //     } else if (params?.row?.is_approved === 0) {
    //       return (
    //         <Box
    //           sx={{
    //             width: "150px",
    //             display: "flex",
    //             justifyContent: "space-evenly",
    //             color: "white",
    //             borderRadius: "8px",
    //           }}
    //         >
    //           <IconButton
    //             onClick={() => handleBlockUnBlockBusiness(params?.row?.id, 1)}
    //             sx={{
    //               bgcolor: "#668B4B",
    //               "&:hover": { bgcolor: "#668B4B" },
    //               borderRadius: "8px",
    //             }}
    //           >
    //             <CheckIcon sx={{ color: "white", fontSize: "18px" }} />
    //           </IconButton>
    //           <IconButton
    //             onClick={() => handleBlockUnBlockBusiness(params?.row?.id, -1)}
    //             sx={{
    //               bgcolor: "#903C39",
    //               "&:hover": { bgcolor: "#903C39" },
    //               borderRadius: "8px",
    //             }}
    //           >
    //             <CloseIcon sx={{ color: "white", fontSize: "18px" }} />
    //           </IconButton>
    //         </Box>
    //       );
    //     }
    //     return "--";
    //   },
    // },
    // {
    //   field: "Action",
    //   headerName: "Action",
    //   minWidth: 100,
    //   headerClassName: "center",
    //   cellClassName: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           width: "150px",
    //           display: "flex",
    //           justifyContent: "space-evenly",
    //           color: "white",
    //           borderRadius: "8px",
    //         }}
    //       >
    //         <IconButton
    //           onClick={() =>
    //             navigate(PathList.BusinessDetails, {
    //               state: {
    //                 data: params?.row?.id,
    //               },
    //             })
    //           }
    //         >
    //           <RemoveRedEyeIcon sx={{ color: "#668B4B", fontSize: "28px" }} />
    //         </IconButton>
    //         <IconButton
    //           onClick={() => handleDelete(params?.row?.id)}
    //           disabled={params?.row?.status === -1 ? true : false}
    //         >
    //           <DeleteIcon
    //             sx={{
    //               color: params?.row?.status === -1 ? "grey" : "#903C39",
    //               fontSize: "28px",
    //             }}
    //           />
    //         </IconButton>
    //       </Box>
    //     );
    //   },
    // },
  ];

  const getBusinessList = async () => {
    try {
      setIsLoading(true);
      const response = await AxiosInstance.get(ApiList.GET_USER_LIST, {
        params: {
          role: 2,
        },
      });
      if (response && response?.data?.s === 1) {
        const formattedUsers = response?.data?.r?.map((user, index) => ({
          rowid: index + 1,
          ...user,
          dob: moment(user?.dob).format("DD/MM/YYYY"),
        }));
        setBusinessList(formattedUsers);
      } else {
        setBusinessList([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBusinessList();
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          p: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <TopTitle title={"Dashboard"} />
        </Box>
        {dashboardLoading ? (
          <Loader sx={{ height: "200px" }} />
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              py: 3,
            }}
          >
            <Box
              sx={{
                width: "250px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "20px",
                borderRadius: "15px",
                p: 3,
                background: "#668B4B70",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                  Total Influencers
                </Typography>
                <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                  {dashboardDetails?.influencer}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "250px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "20px",
                borderRadius: "15px",
                p: 3,
                background: "#668B4B70",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                  Total Business
                </Typography>
                <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                  {dashboardDetails?.bussiness}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "250px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "20px",
                borderRadius: "15px",
                p: 3,
                background: "#668B4B70",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                  Total Jobs
                </Typography>
                <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                  {dashboardDetails?.job}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            gap: "50px",
            pb: 5,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "20px" }}>Recent Creators</Typography>
              <Link sx={{ cursor: "pointer" }} to={PathList.ContentCreator}>
                <Typography sx={{ fontSize: "20px" }}>View All</Typography>
              </Link>
            </Box>{" "}
            <Box
              sx={{
                mt: 3,
              }}
            >
              <TableContainer
                sx={{ height: { xs: "300px", md: "300px", xl: "400px" } }}
                rows={contentCreatorList}
                columns={columns1}
                loading={isLoading}
                hideFooter={true}
                pagination={false}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "20px" }}>Recent Business</Typography>
              <Link sx={{ cursor: "pointer" }} to={PathList.BusinessManagement}>
                <Typography sx={{ fontSize: "20px" }}>View All</Typography>
              </Link>
            </Box>{" "}
            <Box sx={{ mt: 3 }}>
              <TableContainer
                sx={{ height: { xs: "300px", md: "300px", xl: "400px" } }}
                rows={businessList}
                columns={columns}
                loading={isLoading}
                hideFooter={true}
                pagination={false}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
