import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PathList from "./PathList";
import LoginScreen from "../Screens/LoginScreen";
import Layout from "../Screens/Layout/Layout";
import Dashboard from "../Screens/Dashboard/Dashboard";
import BusinessManagement from "../Screens/BusinessManagement/BusinessManagement";
import { Middleware } from "../Config/MiddleWare";
import BusinessDetails from "../Screens/BusinessManagement/BusinessDetails";
import Notifications from "../Screens/Notifications/Notifications";
import ContentCreator from "../Screens/ContentCreator/ContentCreator";
import ContentCreatorDetails from "../Screens/ContentCreator/ContentCreatorDetails";
import CategoryManagement from "../Screens/CategoryManagement/CategoryManagement";
import SocialMedia from "../Screens/SocialMedia/SocialMedia";


const RouteList = () => {
  return (
    <Routes>
      <Route path={PathList.Login} element={<LoginScreen />} />
      <Route
        path={PathList.Dashboard}
        element={<Middleware children={<Layout element={<Dashboard />} />} />}
      />
      <Route
        path={PathList.BusinessManagement}
        element={<Middleware children={<Layout element={<BusinessManagement />} />} />}
      />
      <Route
        path={PathList.ContentCreator}
        element={<Middleware children={<Layout element={<ContentCreator />} />} />}
      />
      <Route
        path={PathList.ContentCreatorDetails}
        element={<Middleware children={<Layout element={<ContentCreatorDetails />} />} />}
      />
      <Route
        path={PathList.BusinessDetails}
        element={<Middleware children={<Layout element={<BusinessDetails />} />} />}
      />
      
      <Route
        path={PathList.Notifications}
        element={<Middleware children={<Layout element={<Notifications />} />} />}
      />
      <Route
        path={PathList.Category}
        element={<Middleware children={<Layout element={<CategoryManagement />} />} />}
      />
      <Route
        path={PathList.SocialMedia}
        element={<Middleware children={<Layout element={<SocialMedia />} />} />}
      />
     </Routes>
  );
};

export default RouteList;
